.App {
    min-height: 100vh;
    padding: 0px;
}
.nav-item {
    font-size: 24px;
}
button.invisible {
    display: none;
}
.highlight {
    background-color: #1f77b4;
}
.hidden {
    display: none;
}
