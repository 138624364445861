.hidden {
    display: none;
}
.formcard {
    background: #f7f7f7;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    border-radius: 10px;
}
#body {
    padding: 100px;
    margin: 10px;
}
.btn-tableau {
    border-color: #1f77b4;
    color: #1f77b4;
}
