.side_navigation {
    /*
    background-color: #ff6f61;
    background-color: #92a8d1;
    */
    background-color: #f7f7f7;
}

button.accordion-button[aria-expanded='true'] {
    /* background-color: PowderBlue;*/
    background-color: #e9ecef;
}

button.dropdown {
    background-color: PowderBlue;
    color: #0d6efd;
}
