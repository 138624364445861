.rst__moveHandle {
    padding-top: 6px;
    text-align: center;
    background-color: #1f77b4;
}
.parent > .rst__moveHandle::before {
}
.child > .rst__rowwrapper {
}
.child > .rst__moveHandle::before {
}
.rst__rowWrapper {
    padding: 8px;
}
.rst__rowContents {
    /*
    border: 3px !important;
    border-bottom: 0px !important;
    /*border-radius: 0px !important;*/
    box-shadow: none;
    border-radius: 10px;
    border-color: #1f77b4;
    border-width: 2px;
    margin-left: 8px;
    left: -20px;
    background-color: white;
}
.rst__addtopic {
    margin-bottom: 10px;
    margin-top: 10px;
    margin-right: 5px;
    font-weight: bold;
    color: green;
    display: inline-block;
}
.rst__deltopic {
    margin-bottom: 10px;
    margin-top: 10px;
    margin-right: 5px;
    font-weight: bold;
    color: red;
    display: inline-block;
}
.rst__lineBlock {
    display: inline-block;
}
.rst__expandButton {
    display: inline-block;
}
.info_badge {
    background-color: #a0cbe8;
    display: none;
    color: black;
}
a.link-secondary:hover {
    display: block;
}
a.navigation:hover {
    font-weight: bold;
}

a.link-secondary {
    font-weight: normal;
    font-size: 18px;
    text-decoration: none;
    color: black;
    position: relative;
}
.link-secondary:after {
    content: '';
    padding: 10px;
    position: absolute;
    left: -25px;
    top: -25px;
}
.rst__moveHandle {
    display: none;
}
.new_topic {
    opacity: 0.7;
}
